
import React from 'react';
import Hero from './sections/Hero';
import Info from './sections/Info';
import Video from './sections/Video';
import Shop from './sections/Shop';
import Footer from './sections/Footer';

function App() {

  if (window.location.pathname.toLowerCase().indexOf('press') > -1) {
    return (
      <iframe 
        id='presskit'
        title="Gaitán's Press Kit"
        src='/press/index.html' 
        style={{ height: '100vh', width: '100vw' }}
        />
    );
  }

  return (
    <div>
      <Hero />
      <Video />
      <Shop />
      <Info />
      <Footer />
    </div>
  );
}

export default App;
