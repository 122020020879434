
import React from 'react';
import IconLink from './IconLink';

import { SiInstagram, SiTwitter, SiSpotify, SiYoutube, SiTiktok } from 'react-icons/si';
import { BiShoppingBag } from 'react-icons/bi';
import { INSTAGRAM_URL, TWITTER_URL, YOUTUBE_URL, TIKTOK_URL, HOODY_PURCHASE_URL, SPOTIFY_URL } from '../constants';

const linkProps = {
  target: '_blank',
  rel: 'noopener noreferrer'
}

const links = [
  {
    name: 'Instagram',
    Icon: SiInstagram,
    url: INSTAGRAM_URL
  },
  {
    name: 'Twitter',
    Icon: SiTwitter,
    url: TWITTER_URL
  },
  {
    name: 'Spotify',
    Icon: SiSpotify,
    url: SPOTIFY_URL
  },
  {
    name: 'Youtube',
    Icon: SiYoutube,
    url: YOUTUBE_URL
  },
  {
    name: 'TikTok',
    Icon: SiTiktok,
    url: TIKTOK_URL
  },
  {
    name: 'Merch',
    Icon: BiShoppingBag,
    url: HOODY_PURCHASE_URL
  },
]

function SocialIcons() {

  return (
    <>
      { links.map(link => (
        <IconLink key={link.name} href={link.url} title={`Gatán's ${link.name}`} {...linkProps}>
          <link.Icon title={`Gatán's ${link.name}`} />
        </IconLink>
      ))}
    </>
  );
}

export default SocialIcons;