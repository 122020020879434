
import React from 'react';
import styled, { css } from 'styled-components';
import SocialIcons from '../components/SocialIcons';
import LinkButton from '../components/LinkButton';
import { FANLINK_URL } from '../constants';

export const HeroContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

export const HeroImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
  opacity: 1;
  transition: opacity 250ms ease-out;
`;

interface HeroContentProps {
  center?: boolean;
  dimmed?: boolean;
}

export const HeroContent = styled.div<HeroContentProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 2em;

  ${props => props.dimmed && css`
    background-color: #0009;
  `}

  ${props => props.center && css`
    align-items: center;
    justify-content: center;
  `}
`;

interface HeroHeaderProps {
  center?: boolean;
  column?: boolean;
}

const HeroHeader = styled.div<HeroHeaderProps>`
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: ${({ column }) => column ? 'column': 'row' };

  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: 720px) {
    justify-content: space-between;
  }

  ${props => props.center && css`
    justify-content: center;
  `}
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

interface HeroProps {
  id?: string;
}

function Hero({ id = 'hero' } : HeroProps) {

  return (
    <HeroContainer id={id}>
      <HeroImage 
        src='/img/airchair-bw.jpg' 
        alt='Gaitán posted shirtless in these streets with a smooth AirChair&trade;' 
        />
      <HeroContent style={{ alignItems: 'center', justifyContent: 'flex-end' }}>
        <HeroHeader>
          <IconContainer>
            <SocialIcons />
          </IconContainer>
          <LinkButton 
            href={FANLINK_URL} 
            target='_blank' 
            rel='noopener noreferrer'
            >
            Stream Gaitán's Music
          </LinkButton>
        </HeroHeader>
        <img 
          src='/img/gaitan.gif' 
          alt='Gaitán'
          style={{ minWidth: 320, width: '30%' }}
          />
        <iframe 
          title="Gaitán's Top Songs on Spotify"
          src='https://open.spotify.com/embed/playlist/5MdZZSNDrWS9VnxdbPOJBP?utm_source=generator&theme=0' 
          style={{ marginTop: '2em', minWidth: 320, width: '30%' }}
          height='80' 
          frameBorder='0' 
          allowFullScreen
          allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
          />
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;