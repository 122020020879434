
import React, { useMemo } from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;

  @media (max-width: 720px) {
    flex-direction: column;
    text-align: center;
  }
`;

function Footer() {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <FooterContainer>
      <div>
        <span style={{ display: 'inline-block' }}>Copyright { year } &copy;Gaitán Gonzales.</span>&nbsp;
        <span style={{ display: 'inline-block' }}>All rights reserved.</span>
      </div>
      <div style={{ height: '1em' }} />
      <div>
        <span style={{ display: 'inline-block' }}>Designed &amp; Coded by</span>&nbsp;
        <span style={{ display: 'inline-block' }}>
          <a href='https://osagiethegreat.com/' title='Osagie the Great' target='_blank' rel='noopener noreferrer'>@OSAGIETHEGREAT</a>
        </span>
      </div>
    </FooterContainer>
  );
}

export default Footer;