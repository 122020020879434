
export const APPLE_MUSIC_URL = 'https://music.apple.com/us/artist/gait%C3%A1n/1264327437';
export const SPOTIFY_URL = 'https://open.spotify.com/artist/66O8IZPEd8cD49UvqYTjs5';
export const SPOTIFY_PLAYLIST_URL = 'https://open.spotify.com/user/1237921653/playlist/5MdZZSNDrWS9VnxdbPOJBP';

export const INSTAGRAM_URL = 'https://www.instagram.com/gaitanmusic';
export const TWITTER_URL = 'https://www.twitter.com/gaitanmusic';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UCL7RUjk-cv9TfPxFOUTLXvA';
export const SOUNDCLOUD_URL = 'https://www.soundcloud.com/gaitanmusic';
export const TIKTOK_URL = 'https://tiktok.com/@gaitanmusic_';
export const FANLINK_URL = 'https://fanlink.to/gaitan';

export const MUSIC_VIDEO_URL_ISSUES = 'https://www.youtube.com/watch?v=J0Yd61tqbV8';
export const MUSIC_VIDEO_URL_445 = 'https://www.youtube.com/watch?v=v1DNlSRdn0U';
export const MUSIC_VIDEO_URL_TURNFORWARDTIME = 'https://www.youtube.com/watch?v=R4qip0KrBpc';

export const HOODY_PURCHASE_URL = 'https://gaitanmerch.company.site/*LTD-900-Hoodie-p186508851#main';
export const ECWID_STORE_ID = 27059100;
export const ECWID_PUBLIC_TOKEN = 'public_37P2aJDQd9BsEJ8xwzUfbmEjWXn96igf';