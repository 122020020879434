
import styled, { css } from 'styled-components';

export interface SectionProps {
  id?: string;
  center?: boolean;
}

const Section = styled.div<SectionProps>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 2em;
  color: white;
  background-color: black;

  ${props => props.center && css`
    align-items: center;
    justify-content: center;
    text-align: center;
  `}
`;

export default Section;