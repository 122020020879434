
import React from 'react';
import { SectionProps } from '../components/Section';
import ScrollAnimation from 'react-animate-on-scroll';

import 'animate.css/animate.min.css';
import SocialIcons from '../components/SocialIcons';
import { HeroContainer, HeroContent, HeroImage } from './Hero';

function Info({ id = 'info' }: SectionProps) {

  return (
    <HeroContainer id={id} style={{ backgroundColor: 'black' }}>
      <HeroImage 
        src='/img/gaitan-hands.jpg' 
        alt='Gaitán posted shirtless in these streets with a smooth AirChair&trade;' 
        style={{ opacity: .5 }}
        />
      <HeroContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ScrollAnimation animateIn='fadeIn'>
          <img 
            src='/img/swbtwnthehilow.gif' 
            alt='Somewhere Between the Hi and Low'
            style={{ width: '30em', maxWidth: '100%' }}
            />
        </ScrollAnimation>
        <ScrollAnimation animateIn='bounceInLeft' delay={120}>
          <p style={{ color: 'white', fontFamily: 'serif', fontWeight: 'bolder', textTransform: 'uppercase', marginBottom: '2em' }}>
            Gaitán is a platinum selling, Billboard #1 producing singer/rapper &amp; producer
          </p>
        </ScrollAnimation>
        <div>
          <SocialIcons />
        </div>
      </HeroContent>
    </HeroContainer>
  );
}

export default Info;