
import React from 'react';
import Section, { SectionProps } from '../components/Section';
import useScript from '../hooks/useScript';
import useInlineScript from '../hooks/useInlineScript';
import { useEffect } from 'react';

const ecwidJs = `
  window.ec=window.ec||{}; 
  window.ec.storefront=window.ec.storefront||{}; 
  window.ec.storefront.enable_navigation=true;
  window.ec.storefront.product_details_layout="THREE_COLUMNS_SIDEBAR_ON_THE_RIGHT";
  window.ec.storefront.product_details_gallery_layout="SINGLE_IMAGE";
  window.ec.storefront.product_details_two_columns_with_right_sidebar_show_product_description_on_sidebar=false;
  window.ec.storefront.product_details_two_columns_with_left_sidebar_show_product_description_on_sidebar=false;
  window.ec.storefront.product_details_show_product_name=true;
  window.ec.storefront.product_details_show_breadcrumbs=true;
  window.ec.storefront.product_details_show_product_sku=false;
  window.ec.storefront.product_details_show_product_price=true;
  window.ec.storefront.product_details_show_in_stock_label=true;
  window.ec.storefront.product_details_show_number_of_items_in_stock=false;
  window.ec.storefront.product_details_show_qty=false;
  window.ec.storefront.product_details_show_wholesale_prices=false;
  window.ec.storefront.product_details_show_product_options=true;
  window.ec.storefront.product_details_show_product_description=true;
  window.ec.storefront.product_details_show_share_buttons=false;
  window.ec.storefront.product_details_position_product_name=100;
  window.ec.storefront.product_details_position_breadcrumbs=200;
  window.ec.storefront.product_details_position_product_sku=300;
  window.ec.storefront.product_details_position_product_price=400;
  window.ec.storefront.product_details_position_product_options=undefined;
  window.ec.storefront.product_details_position_buy_button=600;
  window.ec.storefront.product_details_position_wholesale_prices=700;
  window.ec.storefront.product_details_position_product_description=800;
  window.ec.storefront.product_details_position_share_buttons=900;
  window.ec.storefront.product_details_position_subtitle=500;
  window.ec.storefront.product_details_show_subtitle=true;
  xProductBrowser(
    "categoriesPerRow=3",
    "views=grid(20,3) list(60) table(60)",
    "categoryView=grid",
    "searchView=list",
    "defaultProductId=186508851",
    "id=my-store-27059100"
  );
`

function Shop({ id = 'shop' }: SectionProps) {
  const status = useScript(`https://app.ecwid.com/script.js?27059100&data_platform=code&data_date=2022-01-12`, true);
  useInlineScript(ecwidJs, status === 'ready');

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.document.title = 'Gaitán';
    }, 3000);

    return () => timeout && clearTimeout(timeout);
  }, [status]);

  return (
    <Section style={{ height: 'auto' }}>
      <div id="my-store-27059100"></div>
    </Section>
  );
}

export default Shop;