
import { useEffect, useState } from 'react';

type ScriptStatus = 'ready' | 'loading' | 'idle' | 'error';

function useScript(url?: string, async?: boolean) {
  const [status, setStatus] = useState<ScriptStatus>(url ? 'loading' : 'idle');
  
  useEffect(() => {
    if (!url) {
      setStatus('idle');
      return;
    }

    let script = document.querySelector<HTMLScriptElement>(`script[src="${url}"]`);

    const setAttributeFromEvent = (event: Event) => {
      script?.setAttribute(
        'data-status',
        event.type === 'load' ? 'ready' : 'error'
      );
    };

    if (!script) {
      script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = url;
  
      if (async)
        script.async = true;

      document.body.appendChild(script);

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status') as ScriptStatus);
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('load', setAttributeFromEvent);
        script.removeEventListener('error', setStateFromEvent);
        script.removeEventListener('error', setAttributeFromEvent);
        document.body.removeChild(script);
      }
    }
  }, [url, async]);

  return status;
}

export default useScript;