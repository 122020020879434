
import styled from 'styled-components';

const IconLink = styled.a`
  display: inline-block;
  padding: 0;
  margin: .5em;
  background: transparent;
  color: white;
  border: 0;
  text-align: center;

  &:hover {
    transform: scale(125%);
    opacity: 60%;
  }
`;

export default IconLink;