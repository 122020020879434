
import styled, { css } from 'styled-components';

interface ButtonProps {
  primary?: boolean;
}

const LinkButton = styled.a<ButtonProps>`
  display: inline-block;
  border-radius: 3px;
  padding: .75rem 1rem;
  margin: 0.5rem 1rem;
  background: transparent;
  color: white;
  border: 2px solid white;
  text-align: center;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background: white;
    color: black;
  }

  ${props => props.primary && css`
    background: white;
    color: black;
  `}
`;

export default LinkButton;