
import React, { useState, useCallback, useEffect } from 'react';
import { SectionProps } from '../components/Section';
import BackgroundPlayer from 'react-background-video-player';
import { HeroContainer, HeroContent } from './Hero';
import LinkButton from '../components/LinkButton';
import { MUSIC_VIDEO_URL_ISSUES } from '../constants';

function Video({ id = 'Video' }: SectionProps) {
  const [size, setSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const onResize = useCallback(() => {
    setSize({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }, [setSize]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return (
    <HeroContainer id={id}>
      <BackgroundPlayer
        containerHeight={size.height}
        containerWidth={size.width}
        src='/img/issuesclip.mp4'
        muted={true}
        loop={true}
        />
      <HeroContent center dimmed>
        <LinkButton href={MUSIC_VIDEO_URL_ISSUES} target='_blank' rel='noopener noreferrer'>
          <span style={{ display: 'inline-block' }}>Watch the "Issues"</span>&nbsp;
          <span style={{ display: 'inline-block' }}>Music Video</span>
        </LinkButton>
      </HeroContent>
    </HeroContainer>
  );
}

export default Video;
