
import { useEffect } from "react";

function useInlineScript(code: string, load?: boolean) {
  const args = arguments.length;
  
  useEffect(() => {
    if (args === 1 || load) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = code;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }
  }, [code, load, args]);
}

export default useInlineScript;